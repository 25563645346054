import React, { useRef, useState } from "react";
import {
  StyledCausesList,
  StyledCausesListTitle,
  StyledCausesListItems,
  StyledCausesListItem,
  StyledCausesListImage,
  StyledCausesListLabel,
  StyledCausesListPopup,
} from "./style.jsx";
import useClickOutside from "../../hooks/useClickOutside";

export default (props) => {
  const popup = useRef(null);
  const [visiblePopup, setVisiblePopup] = useState(NaN);

  useClickOutside(popup, () => {
    setVisiblePopup(NaN);
  });

  return (
    <StyledCausesList id={props.id}>
      <StyledCausesListTitle dangerouslySetInnerHTML={{ __html: props.title }}/>
      <StyledCausesListItems>
        {props.features.map((feature, index) => (
          <StyledCausesListItem
            key={index}
            onMouseOver={() => setVisiblePopup(index)}
            onMouseLeave={() => setVisiblePopup(NaN)}
            onClick={() => setVisiblePopup(index)}
          >
            <StyledCausesListImage src={feature.image} loading="lazy" />
            <StyledCausesListLabel
              dangerouslySetInnerHTML={{ __html: feature.description }}
            />
            <StyledCausesListPopup
              ref={popup}
              isVisible={visiblePopup === index}
              dangerouslySetInnerHTML={{ __html: feature.popup }}
            />
          </StyledCausesListItem>
        ))}
      </StyledCausesListItems>
    </StyledCausesList>
  );
};
