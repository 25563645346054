import styled, { css } from "styled-components";
import {
  BP,
  Colors,
  Rem,
} from "../../commons/Theme";

export const StyledCausesList = styled.div`
  padding:${Rem(30)} ${Rem(0)};
  color: #333333;
`;

export const StyledCausesListTitle = styled.div`
  text-align: center;
  font-size: ${Rem(32)};
  margin-bottom: ${Rem(50)};
`;

export const StyledCausesListItems = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
`;

export const StyledCausesListItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${Rem(30)};
  width: 80%;
  max-width: ${Rem(270)};
  height:${Rem(160)};
  @media (${BP.ipad}) {
    margin: initial;
    width: 25%;
  }
`;

export const StyledCausesListImage = styled.img`
  height: ${Rem(80)};
`;

export const StyledCausesListLabel = styled.div`
  text-align: center;
  font-size: ${Rem(15)};
  padding:0 ${Rem(50)};
`;

export const StyledCausesListMobilePopup = styled.div`
  position: absolute;
  width: calc(100vw - ${Rem(40)});
  top: auto;
  left: 50%;
  transform: translate(-50%, 0);
  color: ${Colors.white};
  background-color: ${Colors.gray};
  padding: ${Rem(16)};
  z-index: 2;
  opacity: 1;
  pointer-events: all;

  &:empty {
    opacity: 0;
    pointer-events: none;
  }

  @media (${BP.ipad}) {
    display: none;
  }
`;

export const StyledCausesListPopup = styled.div`
  position: absolute;
  display: none;
  opacity: 0;
  pointer-events: none;
  width: 100%;
  height: auto;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  padding: ${Rem(20)};
  color: ${Colors.white};
  background-color: ${Colors.grayTransparent};

  @media (${BP.ipad}) {
    display: block;
  }

  ${(props) =>
    props.isVisible
    && css`
      opacity: 1;
    `}
`;
